import { render, staticRenderFns } from "./Default.vue?vue&type=template&id=7fa84cde&lang=pug"
import script from "./Default.vue?vue&type=script&lang=ts"
export * from "./Default.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QBtn,QIcon,QToolbarTitle,QSpace,QTooltip,QMenu,QList,QItem,QItemSection,QAvatar,QPageContainer});qInstall(component, 'directives', {ClosePopup});
