
import { Component, Vue } from 'vue-property-decorator'
import { getUploader } from '../../uploader'
import { User } from '@ht-lib/vue-google-signin'
import openURL from 'quasar/src/utils/open-url.js';
import { resetUserProfileId } from '@ht-lib/accounts-common'

interface Guide{
  name: string
  src: string
}

@Component({
  name: 'Default'
})
export default class extends Vue {
  uploader = getUploader()
  openURL = openURL

  guides: Guide[] = [
    { name: 'Uploading Images', src: 'UploadingImagestotheCloud.pdf' },
    { name: 'Committing To The Lab', src: 'CommittingtotheLab.pdf' }
  ]

  get user (): User {
    return this.$auth.user ?? {
      profile: null,
      email: '',
      name: '',
      uid: '',
      picture: '../assets/sad.svg'
    }
  }

  created (): void {
    this.guides.forEach(x => {
      x.src = process.env.BASE_URL + x.src
    })
    window.addEventListener('beforeunload', this.beforeUnload)
  }

  beforeDestroy (): void {
    window.removeEventListener('beforeunload', this.beforeUnload)
  }

  beforeUnload (event: BeforeUnloadEvent): void {
    if (this.uploader.isUploading) {
      event.returnValue = undefined
    } else {
      delete event.returnValue
    }
  }

  signOut (): void {
    this.$auth.signOut()
    resetUserProfileId()
  }

  appsMenu = [
    { icon: 'mdi-webhook', color: 'bookings', text: 'Bookings', link: 'https://cortex.tempestapps.io' },
    { icon: 'mdi-chart-areaspline', color: 'sales-support', text: 'Sales', link: 'https://salessupport.tempestapps.io' },
    { icon: 'mdi-file-document-box-check', color: 'data', text: 'Data', link: 'https://job.tempestapps.io' },
    { icon: 'mdi-image-search', color: 'qc', text: 'QC', link: 'https://images.tempestapps.io' }
  ]
}
